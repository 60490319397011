import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '@/views/404.vue')
    },
    {
        path: '/',
        name: 'GigupWelcome',
        component: () => import(/* webpackChunkName: "HomeSoftware" */ '@/views/landingpage/GigupWelcome.vue'),
    },
    {
        path: '/about',
        name: 'GigupAbout',
        component: () => import(/* webpackChunkName: "ElementTabs" */ '@/views/landingpage/GigupAbout.vue'),
    },
    {
        path: '/teams',
        name: 'GigupTeams',
        component: () => import(/* webpackChunkName: "ElementTabs" */ '@/views/landingpage/GigupTeams.vue'),
    },
    {
        path: '/knowledge',
        name: 'GigupKnowledge',
        component: () => import(/* webpackChunkName: "ElementTabs" */ '@/views/landingpage/GigupKnowledge.vue'),
    },
    {
        path: '/legal',
        name: 'GigupLegal',
        component: () => import(/* webpackChunkName: "ElementTabs" */ '@/views/landingpage/GigupLegal.vue'),
    },
    {
        path: '/contact',
        name: 'GigupContact',
        component: () => import(/* webpackChunkName: "ElementTabs" */ '@/views/landingpage/GigupContact.vue'),
    },
    {
        path: '/webservices',
        name: 'GigupApps',
        component: () => import(/* webpackChunkName: "ElementTabs" */ '@/views/landingpage/GigupApps.vue'),
    },
    {
        path: '/history',
        name: 'GigupHistory',
        component: () => import(/* webpackChunkName: "ElementTabs" */ '@/views/landingpage/GigupHistory.vue'),
    },
];
  
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
  
export default router;
