<template>
    <div class="footer-area-wrapper" :class="FooterStyle">
        <div class="footer-area section-space--ptb_90">
            <div class="container">
                <div class="row footer-widget-wrapper">

                    <!-- Gigup -->
                    <div class="col-lg-4 col-md-6 footer-widget">
                        <div class="footer-widget__logo mb-4 text-center">
                            <router-link to="/">
                                <img src="@/assets/img/gigup-logo/gigup-logo.png" width="320" height="72" class="img-fluid logo-dark" alt="logo">
                                <img src="@/assets/img/gigup-logo/gigup-logo.png" width="320" height="72" class="img-fluid logo-light" alt="logo">
                            </router-link>
                        </div>
                        <ul class="footer-widget__list text-center">
                            <li><i>«Apps die deine Nutzer begeistern.»</i></li>
                            <hr>
                            <li>SaaS, Web- &amp; Mobileapplikationen, CMS / e-Commerce, Landingpage, Coaching &amp; Support, Establishment</li>
                        </ul>
                    </div>

                    <!-- Navigation -->
                    <div class="col-lg-2 col-md-6 footer-widget text-center">
                        <h6 class="footer-widget__title mb-20">Navigation</h6>
                        <ul class="footer-widget__list">
                            <li>
                                <router-link to="/about" class="hover-style-link">About us</router-link>
                            </li>
                            <li>
                                <router-link to="/teams" class="hover-style-link">Unsere Teams</router-link>
                            </li>
                            <li>
                                <router-link to="/knowledge" class="hover-style-link">Knowledge Base</router-link>
                            </li>
                            <li>
                                <router-link to="/legal" class="hover-style-link">Legal &amp; Compliance</router-link>
                            </li>
                        </ul>
                    </div>

                    <!-- Quick Links -->
                    <div class="col-lg-2 col-md-6 footer-widget text-center">
                        <h6 class="footer-widget__title mb-20">Quick links</h6>
                        <ul class="footer-widget__list">
                             <li>
                                <router-link to="/contact" class="hover-style-link">Kontakt</router-link>
                            </li>
                            <li>
                                <router-link to="/legal" class="hover-style-link">Impressum</router-link>
                            </li>
                            <li>
                                <router-link to="/webservices" class="hover-style-link">Unsere Plattform</router-link>
                            </li>
                            <li>
                                <router-link to="/history" class="hover-style-link">History &amp; Releases</router-link>
                            </li>
                        </ul>
                    </div>

                    <!-- Tweet -->
                    <div class="col-lg-3 col-md-6 offset-lg-1 footer-widget">
                        <div class="twitter-feed-slider">
                            <div class="swiper-container twitter-feed-slider__container">
                                <div class="swiper-wrapper twitter-feed-slider__wrapper">
                                    <div class="swiper-slide twitter-feed-slider__single">
                                        <div class="tweet">
                                            <div class="tweet__text">
                                                <i>"Starker Informationsfluss dank cleverem Design."</i>
                                                <a href="https://www.linkedin.com/in/patrick-ammann-239642170/" target="_blank">@Patrick</a>
                                            </div>
                                            <div class="tweet__info">
                                                
                                                <h6 class="tweet__heading"><a href="https://www.linkedin.com/company/gigup-solutions" target="_blank">Gigup Solutions</a></h6>
                                                <span class="tweet__date">Mai 08, 2023</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-copyright-area border-top section-space--ptb_30">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 text-center text-md-start">
                        <span class="copyright-text">
                            &copy; 2023 by <a href="mailto:hello@gigup.ch" class="hover-style-link"><b>{{ app_name }}</b></a>, made with patience.
                        </span>
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        <ul class="list ht-social-networks solid-rounded-icon">
                            <li class="item">
                                <a href="https://github.com/CarlsonBuma/Laravel-Quasar-Authsystem" target="_blank" class="social-link"> <i class="fab fa-github social-link-icon"></i></a>
                            </li>
                            <li class="item">
                                <a href="https://www.youtube.com/@carlson-patrick" target="_blank" class="social-link"> <i class="fab fa-youtube social-link-icon"></i></a>
                            </li>
                            <li class="item">
                                <a href="https://www.linkedin.com/company/gigup-solutions" target="_blank" class="social-link"> <i class="fab fa-linkedin social-link-icon"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["FooterStyle"],
        data() {
            return {
                app_name: process.env.VUE_APP_NAME
            }
        }
    }
</script>
