<style scoped>
    @media (max-width: 340px) {
        #gigup-logo {
            max-width: 200px;
            height: auto;
        }
    }
</style>

<template>
    <header class="header-area navbar-light fixed-top" :class="{'is-sticky': isSticky}">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-xl" id="navbar-example2">

                <!-- Logo -->
                <router-link to="/">
                    <h5 class="">
                        <img src="@/assets/img/gigup-logo/gigup-logo.png" width="240" height="48" alt="gigup-logo" id="gigup-logo">
                    </h5>
                </router-link>

                <!-- Contact -->
                <div class="header-config-wrapper btn-group">
                    <router-link to="/contact" class="ht-btn ht-btn--outline hire-btn d-none d-xl-block">Kontaktiere uns</router-link>
                </div>

                <!-- Toggler -->
                <button class="navbar-toggler m-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <!-- Navigation -->
                <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                    <ul class="nav navbar-nav main-menu element-menu text-center">
                         
                         <!-- Landingpage -->
                         <li class="nav-item">
                            <a class="nav-link" href="#" @click="menuCollapseOnLinkClick">
                                <span>Home</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#services" @click="menuCollapseOnLinkClick">
                                <span>SERVICE</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#howto" @click="menuCollapseOnLinkClick">
                                <span>So funktioniert's</span>
                            </a>
                        </li>
                        
                        <!-- About us -->
                        <li class="nav-item dropdown">
                            <router-link to="" class="bg-transparent nav-link dropdown-toggle" id="homes" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>ÜBER UNS</span>
                            </router-link>
                            <ul class="dropdown-menu text-center" aria-labelledby="homes">
                                <li>
                                    <router-link to="/about" class="dropdown-item">Über uns</router-link>
                                </li>
                                <li>
                                    <router-link to="/teams" class="dropdown-item">Unsere Teams</router-link>
                                </li>
                                <li>
                                    <router-link to="/knowledge" class="dropdown-item">Knowledge Base</router-link>
                                </li>
                                <li>
                                    <router-link to="/legal" class="dropdown-item">Legal &amp; Compliance</router-link>
                                </li>
                            </ul>
                        </li>

                        <!-- Small - Contactus -->
                        <li class="nav-item p-2">
                            <router-link to="/contact" class="ht-btn ht-btn--outline hire-btn d-xl-none">Kontaktiere uns</router-link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
    export default {
        data (){
            return {
                isSticky: false
            }
        },
        mounted(){
            window.addEventListener('scroll', () => {
                let scroll = window.scrollY
                if(scroll >= 200){
                    this.isSticky = true
                } else {
                    this.isSticky = false
                }
            })
        },
        methods: {
            // offcanvas searchbox
            toggleClass(addRemoveClass, className) {
                const el = document.querySelector('#search-overlay');
                if (addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },

            menuCollapseOnLinkClick() {
                document.getElementById('navbarSupportedContent').classList.remove('show')
                this.$router.push('/')
            }
        },
    }
</script>