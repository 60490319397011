<template>
    <div class="main-container">
		<GigupNavbar />
        <router-view />
        <GigupFooter />
        <!-- <OffcanvasSearchBox />
        <OffCanvasMobileMenu /> -->
    </div>
</template>

<script>
    import WOW from "wow.js";
    import consentOptions from '@/modules/cookieConsent.js'
    import GigupNavbar from "@/views/GigupNavbar";
    import GigupFooter from "@/views/GigupFooter";
	// import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
    // import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'

    export default {
        name: 'app',
        components: {
            GigupNavbar,
            GigupFooter,
            // OffcanvasSearchBox,
            // OffCanvasMobileMenu
        },

        mounted(){
            new WOW().init();
            this.$cc.run(consentOptions);
        }
    }
</script>

